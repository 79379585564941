@tailwind base;
@tailwind components;
@tailwind utilities;

#root, .App {
    @apply h-screen;
}

body {
    @apply text-sm;
}

h1 {
    @apply text-2xl font-semibold my-2;
}

h2 {
    @apply text-xl font-semibold my-2;
}

label {
    @apply my-auto;
}

button {
    @apply bg-unae-green text-white rounded-lg p-2 font-semibold hover:bg-unae-green-dark mx-1;
}

button[type=submit] {
    @apply bg-unae-green;
}

button:disabled {
    @apply cursor-not-allowed;
}

button.error {
    @apply bg-red-700 text-white;
}

button.success {
    @apply bg-green-700;
}

button.pending {
    @apply bg-yellow-500;
}

button.validate,
button.validate.available {
    @apply bg-green-500 hover:bg-green-600;
}

button.validate.disabled {
    @apply bg-green-100 hover:bg-green-100 cursor-not-allowed;
}

button.validate.done {
    @apply bg-gray-300 cursor-not-allowed;
}

button.close {
    @apply bg-gray-400 hover:bg-gray-500;
}

div.buttons {
    @apply w-full items-end text-right mt-2;
}

form div {
    @apply grid grid-cols-3 w-1/2 mx-auto p-2;
}

form div input,
form div select {
    @apply col-span-2 ml-2;
}

input[type=checkbox] {
    @apply mr-1 rounded bg-gray-200 border-transparent focus:border-transparent focus:bg-gray-200 text-yellow-500 focus:ring-1 focus:ring-offset-2 focus:ring-yellow-500;
}

input[type=text],
input[type=number],
input[type=password],
select {
    @apply border-2 border-gray-300 focus:border-yellow-500 focus:ring-yellow-500;
}

.connectedCompany {
    @apply text-unae-green font-bold;
}

.labelDisabled {
    @apply opacity-50;
}

.navRouter {
    @apply h-screen;
}

.contentBlock {
    @apply my-2;
}

.App {
    @apply bg-gray-100 h-screen;
}

.appName {
    @apply absolute bottom-0 mb-2;
}

.barCode {
    @apply my-3;
}

.barCode label {
    @apply mr-2;
}

.mainButtonsBlock {
    @apply mb-2;
}

.nav {
    @apply bg-gray-200 text-gray-500 h-screen p-2 fixed w-1/5;
}

.nav h2 {
    @apply p-2;
}

.navlink {
    @apply w-full block p-3 text-left hover:bg-unae-green-dark hover:text-gray-200;
}

.nav-active {
    @apply bg-unae-green text-gray-200;
}

.icon,
.navicon {
    @apply w-5 h-5 inline mr-1;
}

.nav-content {
    @apply h-screen p-2 ml-auto relative w-4/5;
}

.nav-content h1 {
    @apply text-3xl uppercase p-3 font-semibold text-gray-400;
}

.customerNote {
    @apply text-yellow-500 border-2 border-yellow-500 p-2;
}

.customTable {
    @apply w-full;
}

.customTable thead {
    @apply bg-gray-400 text-white p-6;
}

.customTable thead th {
    @apply p-4 text-left;
}

.customTable tbody > tr {
    @apply text-left ;
}

.customTable tbody > tr.rowEven {
    @apply bg-gray-100 hover:bg-unae-green-light;
}

.customTable tbody > tr.rowOdd {
    @apply bg-unae-green-lighter hover:bg-unae-green-light;
}

.customTable td {
    @apply p-2;
}

.customCols2 {
    @apply grid grid-cols-2 my-2;
}

.messagesBox {
    @apply p-2 mt-2 text-left border-2;
}

.messagesSuccess {
    @apply bg-green-100 text-green-600 border-green-400 rounded-md;
}

.messagesWarning {
    @apply bg-yellow-100 text-yellow-600 border-yellow-400 rounded-md;
}

.messagesError {
    @apply bg-red-100 text-red-600 border-red-400 rounded-md;
}

.messageResults {
    @apply text-3xl text-center text-gray-500;
}

.orderLineLocked,
.orderLineLocked {
    @apply text-gray-400 bg-gray-200;
}

.orderLineLocked button {
    @apply text-gray-400 bg-gray-300;
}

.products {
    @apply list-square ml-4 text-2xl;
}

.products li {
    @apply mx-1;
}

.orderPrepare .products li {
    @apply grid grid-cols-2 my-2;
}

.searchInput {
    @apply w-1/3 mb-4;
}

.navUsers .active button {
    @apply bg-unae-green-light;
}

.warningSpot {
    @apply relative inline-flex rounded-full h-3 w-3 bg-yellow-500 animate-ping ml-2;
}

/*
 * Components
 */

.Login form {
    @apply content-center text-center space-y-4;
}

.Login form input {
    @apply block mx-auto;
}

.Modal {
    @apply bg-white bg-opacity-100 static w-2/4 shadow-lg rounded-lg p-4 mx-auto my-auto;
}

.Modal h1 {
    @apply uppercase border-b-2 border-gray-200 pb-4;
}

.Overlay {
    @apply bg-black bg-opacity-70 fixed top-0 right-0 bottom-0 left-0 flex;
}

.Pagination {
    @apply text-base;
}

.Pagination p,
.Pagination ul {
    @apply text-gray-600;
}

.Pagination ul {
    @apply inline-block;
}

.Pagination li {
    @apply inline-block mx-2 cursor-pointer p-2;
}

.Pagination li.active {
    @apply bg-unae-green text-white;
}

.ProductsProgressBar {
    @apply grid grid-cols-3 w-full text-right;
}

.ProductsProgressBar button {
    @apply w-10 h-10;
}

.ProgressBar {
    @apply bg-gray-300 h-full w-full block text-left text-black font-bold p-1 col-span-2;
}

.ProgressBar .fill {
    @apply bg-yellow-500 h-full inline-block relative transition-all text-center;
}

.ProgressBar .fill .productsNumber {
    @apply text-2xl;
}

.ProgressBar .fill.completed {
    @apply bg-green-500;
}